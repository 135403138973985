// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-5-m-js": () => import("./../../../src/pages/5m.js" /* webpackChunkName: "component---src-pages-5-m-js" */),
  "component---src-pages-6-mx-profilozas-js": () => import("./../../../src/pages/6mx-profilozas.js" /* webpackChunkName: "component---src-pages-6-mx-profilozas-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-befolyasolas-es-profilozas-alapkurzus-js": () => import("./../../../src/pages/befolyasolas-es-profilozas-alapkurzus.js" /* webpackChunkName: "component---src-pages-befolyasolas-es-profilozas-alapkurzus-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-sikeres-munkahelyi-kommunikacio-js": () => import("./../../../src/pages/sikeres-munkahelyi-kommunikacio.js" /* webpackChunkName: "component---src-pages-sikeres-munkahelyi-kommunikacio-js" */),
  "component---src-pages-szemelyisegteszt-js": () => import("./../../../src/pages/szemelyisegteszt.js" /* webpackChunkName: "component---src-pages-szemelyisegteszt-js" */),
  "component---src-pages-szolgaltatasok-js": () => import("./../../../src/pages/szolgaltatasok.js" /* webpackChunkName: "component---src-pages-szolgaltatasok-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

